<template>
    <v-form>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <table class="table table-bordered w-100" v-if="typeChart">
                                    <tr>
                                        <th width="20%">Cost Center</th>
                                        <th width="20%">Vendor</th>
                                        <!-- <th width="20%" rowspan="2">Report</th> -->
                                        <!-- <th width="8%">Total Assets</th> -->
                                        <th width="8%">Year/Month</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex justify-space-between align-center">
                                            <div>{{filterLocName}}</div>
                                            <v-dialog
                                            v-model="dialogLoc"
                                            width="500"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    elevation="1"
                                                    dark color="secondary"
                                                    icon
                                                    v-on="on"
                                                    >
                                                    <v-icon>mdi-selection-marker</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>Select Cost Center</v-card-title>
                                                    <v-treeview
                                                    :items="costCenterList"
                                                    :active="[filterDataChart.location]"
                                                    v-model="filterDataChart.location"
                                                    hoverable
                                                    activatable
                                                    dense
                                                    selection-type="independent"
                                                    expand-icon="mdi-chevron-down"
                                                    on-icon="mdi-bookmark"
                                                    off-icon="mdi-bookmark-outline"
                                                    :return-object="true"
                                                    @update:active="changeLoc"
                                                    ></v-treeview>
                                                </v-card>
                                            </v-dialog></div>
                                        </td>
                                        <td>
                                            <v-select
                                            :items="userList" 
                                            item-value="id"
                                            name="item"
                                            outlined
                                            item-text="username"
                                            label="Vendor" 
                                            v-model="userSelected"
                                            hide-details="auto"
                                            ></v-select>
                                        </td>
                                        <!-- <td><span>{{ chartTotalOrderCount || 0}}</span></td> -->
                                        <td>
                                            <!-- <v-select
                                            v-model="selectedMonth"
                                            :items="frequencyOption"
                                            item-text="label"
                                            item-value="value"
                                            label="Year"
                                            outlined
                                            hide-details="auto"
                                            ></v-select> -->
                                            <v-select
                                            v-model="selectedMonth"
                                            :items="frequencyOptionMonth"
                                            item-text="label"
                                            item-value="value"
                                            label="Year/Month"
                                            outlined
                                            hide-details="auto"
                                            ></v-select>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                        <BarChart
                        v-if="loaded && (dataBar == 'all' || dataBar == 'system'  )"
                        :height="200"
                        :chartdata="typeChart"
                        :options="chartOptions" />
                        <v-row  v-if="typeChart.data">
                            <v-col cols="12">
                                <table class="table table-bordered w-100">
                                    <tr>
                                        <td></td>
                                        <td v-for="(th, index) in typeChart.data.datasets" v-bind:key="index">
                                            {{th.label}}
                                        </td>
                                        <!-- <td v-for="(th, index) in typeChart.data.labels" v-bind:key="index">
                                            {{th}}
                                        </td> -->
                                    </tr>
                                    <tr v-for="(date, index) in typeChart.data.labels" v-bind:key="index">
                                        <td>{{date}}</td>
                                        <!-- <td v-for="(data, index2) in status.data" v-bind:key="index2">{{data}}</td> -->
                                        <td v-for="(data, index2) in typeChart.data.datasets" v-bind:key="index2">
                                            <span style="" @click="linkToOrders(typeChart.data.datasets[index2].label, date, userSelected, filterDataChart.location)">{{data.data[index]}}</span>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import BarChart from '@/components/chart/bar'
import LineChart from '@/components/chart/line'
	export default {
        components: {
            BarChart,
            LineChart
        },
        
		data() {
            return {
                loaded: false,
                dataBar: 'all',
                assetList: [],
                userList: [],
                userStatus: null,
                assetSelected: null,
                userSelected: null,
                assetLoading: false,
                workOrderList: [],
                orderChart:[],
                filterDataChart: {
                    frequency: 'monthly',
                    'start-date': '0000-00-00',
                    'end-date':'0000-00-00',
                    location: null,
                },
                selectedMonth: '',
                chartOptions: {
                    legend: {
                        position: 'right',
                    },
                    title: {
                        display: true,
                        text: 'Urgency'
                    },
                    responsive: true,
                    scales: {
                    }
                },
                assetChart: {},
                dateRange: false,
                start_dateMenu: false,
                end_dateMenu: false,
                filterLocName: 'All',
                locationOption: [
                    { name: 'All', id: null}
                ],
                costCenterList: [
                    { name: 'All', id: null}
                ],
                dialogLoc: false
            }
        },
        computed: {
            typeChart:function() {
                return this.assetChart;
            },
            // chartTotalOrderCount:function() {
            //     return this.assetChart.data.grand_total;
            // },
            frequencyOption: function() {
                var arr = []
                var year3Ago = this.$moment().subtract(2, 'years').startOf('year').format('YYYY');
                for (var i = year3Ago; i <= this.$moment().format('YYYY'); i++) {
                    arr.push( { label: i.toString(), value: i.toString() });
                }
                return arr;
            },
            frequencyOptionMonth: function() {
                var arr = []
                var year = ['2023','2024','2025'];
                var months = this.$moment.monthsShort();

                year.forEach((year) => {
                    arr.push ( { label: year, value: year });
                    months.forEach((month) => {
                        arr.push ( { label: month + ' ' +year, value: '01 '+ month + ' ' + year });
                    }) 
                })
                arr.push ( { divider: true });
                return arr;
            },
        },
        methods: {
            load() {
                this.getAssetList();
                this.getUserList();
                this.filterDataChart.frequency = 'monthly';
                this.filterDataChart['start-date'] = this.$moment().startOf('year').format('YYYY-MM-DD');
                this.filterDataChart['end-date'] = this.$moment().format('YYYY-MM-DD');
                this.selectedMonth = this.$moment().format('YYYY');
                this.loadWorkOrder();
                this.getLocations().then((res) => { 
                    res.forEach((loc) => {
                        this.locationOption.push(loc)
                    })
                });
                this.getCostCenters().then((res) => { 
                    res.forEach((loc) => {
                        this.costCenterList.push({id: loc.id, value: loc.id, name: loc.name})
                    })
                });
            },
            loadWorkOrder() {
                this.getJsonChart(this.filterDataChart.frequency, this.filterDataChart['end-date'], this.filterDataChart['start-date'], this.filterDataChart.location, this.userSelected).then((res) => {
                    this.assetChart = res;
                });
                
            },
            getAssetList() {
			    this.getAssets().then((res) => {
                    this.assetList = res
                    this.assetList.unshift({ name: 'All', id: null})
                });
            },
            getUserList() {
			    this.getUsers().then((res) => {
                    this.userList = res;
                    this.userList.unshift({ username: 'All', role: '', id: null})
                });
            },
			async getAssets() {
                try {
                    this.assetLoading = true
                    const res = await this.$api.assetAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.assetLoading = false;
                }
            },
            async getLocations() {
                try {
                    const res = await this.$api.location.listAll();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getCostCenters() {
                try {
                    const res = await this.$api.invoiceCostCenter.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
                try {
					const res = await this.$api.user.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getOrderChart() {
                try {
                    const res = await this.$api.statistics.orderRange( this.filterDataChart );
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getJsonChart( frequency, end_date, start_date, location, created_by) {
                try {
                    this.loaded = false
                    let res;
                    res = await this.$api.statistics.jsonVendorStatusChart({frequency: frequency, 'end-date': end_date, 'start-date': start_date, costcenter: location, 'worker':created_by });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loaded = true
                }
			},
            openDateRange() {
                this.dateRange = !this.dateRange;
            },
            assetFilter (item, queryText, itemText) {
                if (item.name !== 'All') {
                    var data = item.name.toLowerCase() + ' ' + item.manual_tag.toLowerCase() + ' ' + item.id
                    var searchText = queryText.toLowerCase()
                    return data.indexOf(searchText) > -1 
                }
            },
            changeLoc(e) {
                this.filterDataChart.location = e[0].id
                this.filterLocName = e[0].name
                this.dialogLoc = false
            },
            linkToOrders(status, yearmonth, vendor, costcenter){
                // if (this.$moment(yearmonth, 'YYYY-MM',true).isValid()) {
                //     this.$router.push({ 
                //         name: 'workOrderList', 
                //         params: { 
                //             status: status, 
                //             dateStart: this.$moment(yearmonth).startOf('month').format('YYYY-MM-DD'), 
                //             dateEnd: this.$moment(yearmonth).endOf('month').format('YYYY-MM-DD'), 
                //             vendorId: vendor, 
                //             costcenterId: costcenter 
                //         } 
                //     })
                // }
                // if (this.$moment(yearmonth, 'YYYY-MM-DD',true).isValid()) {
                //     this.$router.push({ 
                //         name: 'workOrderList', 
                //         params: { 
                //             status: status, 
                //             dateStart: this.$moment(yearmonth).startOf('month').format('YYYY-MM-DD'), 
                //             dateEnd: this.$moment(yearmonth).endOf('month').format('YYYY-MM-DD'), 
                //             vendorId: vendor, 
                //             costcenterId: costcenter 
                //         } 
                //     })
                // }
            }
        },
        mounted(){
            this.load();
        },
        watch: {
            'dataBar': function(newVal) {
                this.chartOptions.title.text = newVal.charAt(0).toUpperCase() + newVal.slice(1);
                this.loadWorkOrder();
            },
            filterDataChart: {
                handler(val){
                    this.loadWorkOrder();
                },
                deep: true
            },
            'dateRange': function(newVal) {
                this.loadWorkOrder();
            },
            'assetSelected': function(newVal) {
                this.loadWorkOrder();
            },
            'userSelected': function(newVal) {
                if (newVal == null) {
                    this.userStatus = null; 
                } else {
                    if (this.userStatus == null ) {
                        this.userStatus = 'worker'
                    }
                }
                this.loadWorkOrder();   
            },
            'selectedMonth': function(newVal) {
                console.log(newVal)
                if (this.$moment(newVal, 'DD MMM YYYY',true).isValid()) {
                    this.filterDataChart.frequency = 'daily';
                    this.filterDataChart['start-date'] = this.$moment(newVal).startOf('month').format('YYYY-MM-DD');
                    this.filterDataChart['end-date'] = this.$moment(newVal).endOf('month').format('YYYY-MM-DD');
                } else {
                    if (this.$moment(newVal, 'YYYY',true).isValid()) {
                        this.filterDataChart.frequency = 'monthly';
                        this.filterDataChart['start-date'] = this.$moment('01/01/'+newVal).startOf('year').format('YYYY-MM-DD');
                        this.filterDataChart['end-date'] = this.$moment('01/01/'+newVal).endOf('year').format('YYYY-MM-DD');
                    }
                }
            },
        }
    }
</script>